
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import axios from "axios";
import { store } from "@/utils/store";

export default defineComponent({
  components: {
    Header,
    Footer,
  },
  name: "App",
  data() {
    return {
      formData: {
        email: "",
        lastName: "",
        firstName: "",
        businessName: "",
        category: "",
        mobile: "",
        code: "",
        isSent: false,
        loading: false,
      },
      countdown: 0,
      emailError: "",
      selectList: [{ id: 0, category: "" }],
    };
  },
  computed: {
    isValidEmail() {
      const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
      if (this.formData.email && !this.formData.email.match(emailPattern)) {
        return "Invalid email address";
      } else {
        return "";
      }
    },
  },
  methods: {
    handleSubmit() {
      if (!this.formData.email) {
        ElMessage({
          message: "please enter email",
          type: "warning",
        });
        return;
      }
      if (!this.formData.isSent) {
        ElMessage({
          message: "please send code",
          type: "warning",
        });
        return;
      }
      if (!this.formData.code) {
        ElMessage({
          message: "please enter code",
          type: "warning",
        });
        return;
      }
      var config = {
        method: "post",
        url: `/api/ai-health/master-station/company-auth/register?businessName=${this.formData.businessName}&firstName=${this.formData.firstName}&lastName=${this.formData.lastName}&categoryId=${this.formData.category}&email=${this.formData.email}&code=${this.formData.code}&mobile=${this.formData.mobile}
        `,
      };

      axios(config)
        .then((response) => {
          if (response.data.code === 200) {
            ElMessage({
              message: response.data.msg,
              type: "success",
            });
            store.handleSetIsLogin();

            this.$router.push("/");
          } else {
            ElMessage({
              message: response.data.msg,
              type: "error",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleSendCode() {
      if (this.formData.loading || this.isValidEmail) {
        return;
      }
      if (!this.formData.email) {
        ElMessage({
          message: "please enter email",
          type: "warning",
        });
        return;
      }
      this.formData.loading = true;

      var config = {
        method: "post",
        url: `/api/ai-health/master-station/company-auth/register-code?email=${this.formData.email}&type=zh`,
      };
      axios(config)
        .then((response) => {
          if (response.data.code === 200) {
            ElMessage({
              message: "send success",
              type: "success",
            });
            this.countdown = 60;
            const countdown = setInterval(() => {
              if (this.countdown > 0) {
                this.countdown = this.countdown - 1;
              } else {
                clearInterval(countdown);
                this.countdown = 0;
              }
            }, 1000);
            this.formData.isSent = true;
          } else {
            ElMessage({
              message: response.data.msg,
              type: "error",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.formData.loading = false;
        });
    },
  },
  mounted() {
    var config = {
      method: "get",
      url: "/api/ai-health/company-category/getList?parentId=0",
    };

    axios(config)
      .then((response: { data: { data: { id: number; category: string }[] } }) => {
        this.selectList = response.data.data;
      })
      .catch(function (error: string) {
        console.log(error);
      });
  },
});
